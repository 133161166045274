<template>
    <!--Light Box-->
    <div class="lightbox" v-if="which">
        <!--First Run-->
        <div class="card firstrun" v-if="isIntro">
            <div class="close" @click="onCloseClick" ><i class="fa fa-times" aria-hidden="true"></i></div>
            <img :src="branding.Logo">
            <h1> Welcome to {{branding.ProductName}} Extension.</h1>
            <ul class="fr">
                <li>{{branding.IntroLI1}}</li>
            </ul>
            <div class="btnWrapper">
                <button class="ok left"  @click="onCloseClick()" >{{branding.SearchLabel}}<span id="q">  {{searchTerm}}</span></button>
                <button class="ok left fade" @click="pickLearnTips()" >Quick Tips</button>
            </div>
        </div>
        <!-- End of First Run-->
        <!-- Setting panel-->
        <div class="card firstrun" v-if="firstRunSettings && showSettings">
            <div class="close" @click="onCloseClick" ><i class="fa fa-times" aria-hidden="true"></i></div>
            <img :src="branding.Logo">
            <h1> Get Started by selecting your {{branding.ProductType}} provider.</h1>
            <ul>
                <li v-for="provider in productSearchProviders">
                    <label>
                        <input type="radio" v-model="productEngine" :value="provider.id" :checked="provider.id === productEngine">
                        {{provider.name}}
                        <span v-if="provider.isDefault">(Default)</span>
                    </label>
                </li>
            </ul>
            <div class="btnWrapper">
                <button class="ok left" @click="saveSetting()" >Select and continue</button>
            </div>
        </div>
        <!-- End of Setting panel-->
        <!--Tips-->
        <div class="card firstrun" v-else-if="isTutorial">
            <div class="close" @click="onCloseClick" ><i class="fa fa-times" aria-hidden="true"></i></div>
            <img :src="branding.Logo">
            <h1>Getting the most out of {{branding.ProductName}}</h1>
            <img :src="branding.TypeGif" class="type">
            <ol>
                <li>Go to the address bar and type <strong>"{{branding.MetaSearchKeyword}}"</strong></li>
                <li>After <strong>"{{branding.MetaSearchKeyword}}"</strong> hit spacebar then type your search.<br> (e.g. <strong>{{branding.SearchExample}}</strong>)</li>
            </ol>
            <div class="btnWrapper">
                <button class="ok left" v-show="false">Back</button>
                <button class="ok left" @click="onCloseClick()">OK</button>
            </div>
        </div>
        <!-- End Tips-->
    </div>
    <!--End of light box-->
</template>
<script>
    "use strict";

    import {getBranding, PRODUCT_SEARCH_PROVIDERS, DEFAULT_PRODUCT_SEARCH_PROVIDER} from '../branding'
    import {getExtSetting, setExtSetting} from "../storage"
    import {arrow} from '@tightrope/newtab'

    if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(search, this_len) {
            if (this_len === undefined || this_len > this.length) {
                this_len = this.length;
            }
            return this.substring(this_len - search.length, this_len) === search;
        };
    }
    export default {
        name:'first-run',
        props:["which", "onClose", "onNext", "pEngine", "saveSearchUrl", "firstRunSettings"],
        data(){
            return {
                productSearchProviders: PRODUCT_SEARCH_PROVIDERS,
                productEngine: this.pEngine,
                branding: getBranding(),
                showSettings: this.saveSearchUrl && !this.pEngine,
            }
        },
        async created() {
            if (this.firstRunSettings && !this.showSettings) {
                try {
                    if (!PRODUCT_SEARCH_PROVIDERS[this.productEngine]) {
                        this.productEngine = DEFAULT_PRODUCT_SEARCH_PROVIDER;
                    }
                    await setExtSetting("productEngine", PRODUCT_SEARCH_PROVIDERS[this.productEngine].id);
                    if (this.saveSearchUrl) {
                        await setExtSetting("productEngineUrl", PRODUCT_SEARCH_PROVIDERS[this.productEngine].url);
                    }
                }
                catch(e) {
                    // no extension
                }
                finally {
                    this.$emit('update:pEngine', this.productEngine);
                }
            }
            else if (this.firstRunSettings) {
                try {
                    this.productEngine = await getExtSetting("productEngine");
                    if (this.productEngine) {
                        this.showSettings = false;
                        this.$emit('update:pEngine', this.productEngine);
                    }
                    else {
                        this.productEngine = DEFAULT_PRODUCT_SEARCH_PROVIDER;
                    }
                }
                catch(e) {
                    // no extension
                    this.productEngine = DEFAULT_PRODUCT_SEARCH_PROVIDER
                }
            }
        },
        mounted() {
            arrow("newtab");
        },
        methods:{
            canShow(name){
                return  this.which && this.which.endsWith(name)
            },
            pickLearnTips(){
                switch(this.which){
                    case 'intro':
                        this.onNext('it');
                    return;
                }
            },
            onCloseClick(){
                this.onClose && this.onClose(...arguments);
            },
            async saveSetting() {
                try {
                    await setExtSetting("productEngine", PRODUCT_SEARCH_PROVIDERS[this.productEngine].id);
                    if (this.saveSearchUrl) {
                        await setExtSetting("productEngineUrl", PRODUCT_SEARCH_PROVIDERS[this.productEngine].url);
                    }
                }
                catch(e) {
                    // no extension
                }
                this.$emit('update:pEngine', this.productEngine);
                this.onNext('i');
                this.showSettings = false;
            },
        },
        computed:{
            isTutorial() { return this.canShow('tutorial'); },
            isIntro() { return this.canShow('intro'); },
        }
    };
</script>