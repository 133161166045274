const defaultBranding = {
    // type of product - eg, "forms"
    ProductType: "My Dictionary Search",
    // menu text to select product search tab - eg, "Forms Search"
    SearchLabel: "Search",
    // meta keyword specified in manifest (might not be the same as product type) - eg, "forms"
    MetaSearchKeyword: "dictionary",
    // example searches on first run - eg, "Taxes, Immigration, US"
    SearchExample: "sesquipedalian, cavalier",
    // eg, "Search form titles or tags"
    AutocompletePlaceholder: "Enter keyword to search word meanings",
    // eg, "Search our database for forms by typing in a form query in the search box above."
    IntroLI1: "Get started searching meanings and definitions of words.",
    // eg, "You can also browse our forms database by 'tag' located on the left side menu of the page."
    IntroLI2: "",
    FirstRunSettings: false,
}

const windowBranding = window.branding || {};

const PRODUCT_SEARCH_PROVIDERS = {
    mydictionary: {
        name: "My Dictionary",
        id: "mydictionary",
        isDefault:true,
        url: ""
    },
    dictionary: {
        name: "Dictionary.com",
        id: "dictionary",
        isDefault: false,
        url: "https://www.dictionary.com/browse/{{QUERY}}"
    },
    google: {
        name: "Google Dictionary",
        id: "google",
        isDefault: false,
        url: "https://www.google.com/search?q=dictionary&dobs={{QUERY}}"
    },
    yahoo: {
        name: "Yahoo Dictionary",
        id: "yahoo",
        isDefault: false,
        url: "https://dictionary.search.yahoo.com/search;_ylt=AwrC1zH0AbpcKjQAogZ5GVxH;_ylc=X1MDMTE5NzIxODE2OQRfcgMyBGZyA3lmcC10BGdwcmlkAy5aY1VJLlN6UXkyQkdLVXRrWmp3OUEEbl9yc2x0AzAEbl9zdWdnAzEwBG9yaWdpbgNkaWN0aW9uYXJ5LnNlYXJjaC55YWhvby5jb20EcG9zAzAEcHFzdHIDBHBxc3RybAMEcXN0cmwDNQRxdWVyeQNoYXBweQR0X3N0bXADMTU1NTY5NDA3Nw--?fr2=sb-top-dictionary.search&fr=yfp-t&p={{QUERY}}"
    }
}

const altSearchProvidersUrls = function(query) {
    const altProviders = Object.entries(PRODUCT_SEARCH_PROVIDERS).filter(([key, value]) =>  !value.isDefault);
    return altProviders.map(([key, value]) => {
        return {
            name: value.name,
            url: value.url.replace("{{QUERY}}", query),
        }
    });
}

const DEFAULT_PRODUCT_SEARCH_PROVIDER = PRODUCT_SEARCH_PROVIDERS["mydictionary"].id;

function getBranding(){
    return {
        ...defaultBranding,
        ...windowBranding,
    }
}

export {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
    DEFAULT_PRODUCT_SEARCH_PROVIDER,
}