import {getBranding}  from "./branding"
import {sendMessageToExt} from "./storage"
import {isFirefox} from "./common"
const branding = getBranding();

let  TOP_DOMAIN = (typeof branding !== "undefined" && branding.RootDomain) || (function(){
    let parts=window.location.hostname.split(/\./);
    let domain = parts.length == 1?window.location.hostname:parts.slice(-2).join(".")
    return (domain + (window.location.port?(":"+window.location.port):"")).toLowerCase();
})();

const CURRENT_HOST = `${new URL(window.location).origin}`;

const API_ROUTE ="/api";

const WEB_SEARCH_URL = `https://services.${TOP_DOMAIN}/crx/search.php`
const SEARCH_API_URL= `${API_ROUTE}`

function openUrl(toOpen, newTab = true) {
    if (!newTab) {
        return window.open(toOpen, (window.self !== window.top ? '_parent' : '_self'));
    }
    else if (isFirefox) {
        return sendMessageToExt({task: "openUrl", url: toOpen});
    }
    else {
        window.open(toOpen, '_blank');
    }
}

export {
    WEB_SEARCH_URL,
    SEARCH_API_URL,
    openUrl,
    TOP_DOMAIN,
    CURRENT_HOST,
}