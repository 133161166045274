<template>
    <div class="firstRun-text" v-show="canShow">
        <div class="bclose" @click="onCloseIntroBox()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
        <strong>Welcome to {{branding.ProductName}}</strong>
        <ul>
            <li>
                {{branding.IntroLI1}}
            </li>
        </ul>
    </div>
</template>

<script>
    "use strict";
    import {getBranding} from "../branding"

    export default {
        name:'intro-box',
        data(){
            return {
                branding: getBranding(),
                closed: false,
            }
        },
        methods: {
            onCloseIntroBox () {
                this.closed = true;
            },
        },
        computed: {
            canShow() { return !this.closed },
        }
    };
</script>