<template>
    <!--main wrapper-->
    <div class="serp-wrapper">
        <!-- Bookmark Popup  -->
        <first-run :which="whichPop" :onClose="onCloseHelp" :onNext="onNextPop" :pEngine="productEngine" />
        <!--header wrapper-->
        <div class="header-wrapper">
            <!--nav-->
            <div id="serp-nav">
                <div id="job-btn" class="serp-nav-item" :class="{active:productSearch}" @click="toProductSearch()">{{branding.SearchLabel}}</div>
                <div id="web-btn" class="serp-nav-item" :class="{active:!this.productSearch}" @click="toWebSearch()">Web Search</div>
            </div>
            <!--End of nav-->
            <!--header-->
            <div class="serp-header">
                <div class="serp-logo"><img :src="branding.Logo"></div>
                <div class="thisSearch" v-show="productSearch">
                    <div class="search pad-right">
                        <input ref="queryInput" :placeholder="branding.AutocompletePlaceholder" v-model="query" @keydown.enter="toDictionarySearch()"/>
                        <div class="s-icon">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div class="error" v-if="queryError">This field is required, please enter</div>
                    </div>
                    <button id="btn-search" @click="toDictionarySearch()">{{branding.SearchLabel}}</button>
                </div>
                <div class="webSearch" v-show="!productSearch">
                    <div class="search web">
                        <input ref="webSearch" id="" placeholder="Search the web" v-model="wQuery" @keydown.enter="startWebSearch()"/>
                        <div class="s-icon"><i class="fa fa-globe" aria-hidden="true"></i></div>
                        <div class="error" v-if="queryError">This field is required, please enter</div>
                    </div>
                    <button id="btn-search" @click="startWebSearch()">Web Search</button>
                </div>
            </div>
            <!--End of Header-->
        </div>
        <!--End of header wrapper-->
        <!--serp results-->
        <div class="bodyWrapper">
            <div class="serp-results">
                <!--Left col-->
                <div class="serp-results-left">
                    <!-----First Run Add on------>
                    <intro-box v-show="showIntro"/>
                    <!-----End of First Run Add on------>
                    <div class="serp-stats" v-show="hasSearchResults">
                        Showing {{numResults}} results for
                        <span class="kw"> {{searchTerm}}</span>
                    </div>
                    <!--NoResults-->
                    <div class="serp-noresults" v-show="!hasSearchResults && searchTerm!='' && searchCompleted">
                        <h3>Sorry, no results found for  <span class="kw">{{searchTerm}}</span></h3>
                        <div v-if="hasSuggestions" class="srl-suggestions">
                            <h3>Try one of the search suggestions:</h3><br/>
                            <a v-for="sug in suggestions" class="srl-cat suggest" href="#" :title="sug" @click="doRecentSearch(sug)"><strong>{{sug}}</strong></a>
                        </div>
                    </div>
                    <!-- End of NoResults-->
                    <div v-show="hasSearchResults">
                        <!--results-->
                        <div class="srl" v-for="(result,index) in records">
                            <div class="srl-info">
                                <h3 class="srl-title"><span class="fonts">{{result.text}}</span> <span class="srl-type">{{result.lexicalCategory.text}}</span></h3>
                                <div v-if="result.pronunciations">
                                    <span class="srl-pronouce">\ {{result.pronunciations[0].phoneticSpelling}} \</span><span onclick="this.firstElementChild.play();"><audio><source :src="result.pronunciations[0].audioFile" type="audio/mp3"></audio><i class="fa fa-volume-up sound"></i></span>
                                </div>
                                <div v-else-if="result.entries.pronunciations">
                                    <span class="srl-pronouce">\ {{result.entries[0].pronunciations[0].phoneticSpelling}} \</span><span onclick="this.firstElementChild.play();"><audio><source :src="result.entries[0].pronunciations[0].audioFile" type="audio/mp3"></audio><i class="fa fa-volume-up sound"></i></span>
                                </div>
                                <div class="srl-entry">Definition of <span class="italic">{{result.text}}</span> (Entry {{index+1}} of {{numResults}})</div>
                                <div class="srl-description">
                                    <ul>
                                    <li v-for="defItem in result.entries[0].senses">
                                        <ul>
                                            <li v-for="def in defItem.definitions">
                                                <span class="srl-def">{{def}}</span>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li v-for="example in defItem.examples">
                                                <span class="srl-vis" >{{example.text}}</span>
                                            </li>
                                        </ul>
                                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!--end of results-->
                    </div>
                    <!--Browse Panel-->
                    <!--End of left col-->
                </div>
                <!--Right col -->
                <div class="rightCol">
                    <!--Search History-->
                    <div class="module-right side-list history">
                        <h2><i class="fa fa-history" aria-hidden="true"></i>Recent searches</h2>
                        <div class="list" v-for="recent in recentSearches" @click="doRecentSearch(recent)">{{recent}}</div>
                    </div>
                    <!--end of search history-->
                </div>
                <!--End of Right col-->
            </div>
        </div>
        <!--End of resultes-->
        <!--footer-->
        <footer>
            <div class="serp-footer">
                <div class="logoFooter"><img :src="branding.Logo"><span><span id="brandDetails"><i class="fa fa-copyright" aria-hidden="true"></i> {{branding.Trademark}}</span> </span></div>
                <ul>
                    <li><a :href="`https://${branding.RootDomain}/terms-of-service/`">Terms of Service </a></li>
                    <li><a :href="`https://${branding.RootDomain}/privacy-policy/`">Privacy</a></li>
                    <li><a :href="`https://${branding.RootDomain}/contact-us/`">Contact Us</a></li>
                    <li>Powered by<a class="powered"  target="_blank" href='https://www.oxforddictionaries.com/'>Oxford Dictionaries</a> </li>
                </ul>
            </div>
        </footer>
        <!--end of footer-->
    </div>
    <!-- End of main wrapper -->
</template>

<script>
    "use strict"
    import bp from "babel-polyfill"
    import queryString from 'query-string'
    import 'whatwg-fetch'
    import Humanize from 'humanize-plus'
    import {getSetting, setSetting, getGuid} from "../storage"
    import {WEB_SEARCH_URL, openUrl, SEARCH_API_URL} from '../urls'
    import FirstRun from '../components/FirstRun.vue'
    import IntroBox from '../components/IntroBox.vue'
    import {getBranding, PRODUCT_SEARCH_PROVIDERS, DEFAULT_PRODUCT_SEARCH_PROVIDER, altSearchProvidersUrls} from "../branding"
    import {documentReady} from '../common'
    import "../analytics"

    function validatePop(name){
        switch(name){
            case "intro":
            case "i":
                return "intro"
            case "t":
            case "tutorial":
                return "tutorial"
            case "it":
                return "intro-tutorial"
        }
        return false;
    }

    export default {
        name: 'serp',
        components: {
            'first-run': FirstRun,
            'intro-box': IntroBox
        },
        data() {
            let qs = queryString.parse(window.location.search);
            return {
                openUrl,
                recentSearches: [],
                productSearch: true,
                records: [],
                suggestions: [],
                searchCompleted: false,
                query: qs.q || '',
                wQuery: '',
                queryError: false,
                searchTerm: '',
                guid: qs.guid,
                productEngine: qs.engine,
                whichPop: validatePop(qs.pop) || false,
                branding: getBranding(),
                showIntro: true,
            }
        },
        mounted() {
            documentReady.then(async() => {
                this.recentSearches = JSON.parse(getSetting("recentSearches")) || [];
                try {
                    this.guid = await getGuid();
                }
                catch (e) {
                    // no extension or guid query paraneter
                }
                this.showIntro=this.whichPop;
                this.$refs.queryInput.focus();
                this.handleSearchUrl();
                window.addEventListener('popstate', async (event) => {
                    await this.handleOnPopState(event);
                });
            })

        },
        methods: {
            async startWebSearch() {
                let searchParams = {
                    k: this.wQuery,
                    guid: this.guid
                }
                await openUrl(`${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`, false)
            },
            validateSearch(withFocus) {
                let {
                    query
                } = this;
                let errs = false;
                if (this.query == "") {
                    errs = errs || {}
                    errs.query = "This field is required, Please enter"
                }
                this.queryError = errs;
                if (withFocus) {
                    if (errs.query) this.$refs.queryInput.focus();
                }
                return errs === false;
            },
            addToBrowserHistory() {
                let qs  = {
                    ...queryString.parse(window.location.search),
                    q:this.query,
                }
                //Add guid if there is one.
                if(this.guid){ qs.guid = this.guid }
                qs = queryString.stringify(qs);
                let newUrl = this.baseUrl + "?" + qs;
                window.history.pushState({query:this.query},`${this.query}`,newUrl);
            },
            async handleOnPopState(ev){
                let state= ev.state;
                if (state && state.query) {
                    if (this.query !== state.query){
                        this.query = state.query;
                        await this.doSearch(this.query, false);
                    }
                }
            },
            async doSearch(term, addToHistory = true) {
                this.showIntro=false;
                if (this.productEngine && this.productEngine != DEFAULT_PRODUCT_SEARCH_PROVIDER) {
                        await openUrl(PRODUCT_SEARCH_PROVIDERS[this.productEngine].url.replace("{{QUERY}}", term), false);
                    }
                    else {
                        if (addToHistory) {
                            this.addToBrowserHistory();
                            this.recentSearches = this.recentSearches.filter(x => x !== this.query);
                            if (this.recentSearches.unshift(this.query) > 10){
                                this.recentSearches.pop()
                            }
                            setSetting("recentSearches", JSON.stringify(this.recentSearches));
                        }
                        this.records=[];
                        this.suggestions=[];
                        this.searchTerm = term;
                        this.searchCompleted = false;
                        let url=`${SEARCH_API_URL}/entries/${term}`;
                        this.records=await this.getData(url);
                        if (this.numResults==0)
                        {
                            const check_url = `${SEARCH_API_URL}/check/${term}`;
                            const checkReq = await fetch(check_url);
                            if (checkReq.ok) {
                                const recordsCheck = await checkReq.json();
                                if (recordsCheck && recordsCheck.results){
                                    if (recordsCheck.results[0].lexicalEntries[0].inflectionOf.length>0)
                                    {
                                        term=recordsCheck.results[0].lexicalEntries[0].inflectionOf[0].text;
                                        url=`${SEARCH_API_URL}/entries/${term}`;
                                        this.records=await this.getData(url);
                                    }
                                }
                            }
                        }
                        this.searchCompleted = true;
                    }
            },
            async getData(url){
                const recordsReq = await fetch(url);
                if (recordsReq.ok) {
                    const recordsRes = await recordsReq.json();
                    if (recordsRes && recordsRes.results) {
                        if (recordsRes.results[0].lexicalEntries.length>0)
                            return recordsRes.results[0].lexicalEntries;
                    }
                }
                return [];
            },
            toWebSearch() {
                this.productSearch = false;
                this.$nextTick(() => this.$refs.webSearch.focus())
            },
            toProductSearch() {
                this.productSearch = true;
                this.$nextTick(() => {
                    this.$refs.queryInput.focus()
                });
            },
            async handleSearchUrl() {
                let qs = queryString.parse(window.location.search);
                let {q} = qs;
                this.query = q || "";
                if (this.query) {
                    await this.doSearch(this.query, false);
                }
            },
            onNextPop(tag) {
                this.whichPop = validatePop(tag) || false;
            },
            onCloseHelp() {
                switch (this.whichPop) {
                    case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
                }
                this.whichPop = false
            },
            async doRecentSearch(term) {
                this.query = term;
                await this.doSearch(this.query);
            },
            async toDictionarySearch() {
                if (!this.validateSearch(true)) return;
                await this.doSearch(this.query);
            }
        },
        computed: {
            hasSearchResults() {
                return this.numResults > 0;
            },
            hasSuggestions(){
                return this.suggestions.length;
            },
            numResults() {
                return this.records.length;
            },
            baseUrl() {
                return window.location.href.split('?')[0];
            }
        }
    }
</script>

<style>
    @import './css/serp.css';
</style>